

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: scroll;

    --primary: #ffb914;
    --secondary: #272c46;

    --background: #f6f9fc;
    --foreground: #ffffff;
}
* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}
.ant-list-bordered .ant-list-item {
    padding-right: 0px;
}
.ant-card {
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 6px !important;
    border-width: 0.5px;
    border-color: rgb(229, 232, 235);
}
.ant-card .ant-card-head {
    /*background: RGBA(253, 247, 242, 1.00) */
    background: #fafafa;
}
.ant-card .ant-card-head-title {
    font-weight: 700;
}

.ant-form-item {
    margin-bottom: 12px;
}

.ant-input {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-width: 0.5px;
    border-color: rgb(229, 232, 235);
    border-radius: 4px;
}
.ant-select {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 4px !important;
}
.ant-select .ant-select-selector {
    height: 44px !important;
    padding: 6px 11px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-width: 0.5px;
    border-radius: 4px;
    border-color: rgb(229, 232, 235);
}


.ant-btn {
    font-weight: 600 !important;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}
.ant-btn-default {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-width: 0.5px;
    border-color: rgb(229, 232, 235);
}

.ant-btn-link {
    box-shadow: none;
}

.tableau-web-connect-page .inner p {
    align-self: center;
    margin-bottom: 0;
}

.tableau-web-connect-page .inner .para {
    display: flex;
    color: #666;
}

.tableau-web-connect-page .inner .para p {
    margin-right: 5px;
}

.common-header {
    display: none;
}
.ant-list-bordered {
    border-radius: 6px;
}
.tableau-web-connect-page h2 {
    font-size: 32px;
    margin-bottom: 0px;
    text-align: center;
}

.tableau-web-connect-page h3 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.tableau-web-connect-page .item {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-width: 0.5px;
    border-color: rgb(229, 232, 235);
    border-radius: 4px;
}
.tableau-web-connect-page {
    background-color: #f6f9fc;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableau-web-connect-page .holder {
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}
.tableau-web-connect-page .holder .hidden {
    display: none;
}

.tableau-web-connect-page .inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}
.tableau-web-connect-page .inner.first {
    justify-content: space-between;
    align-items: center;
}
.tableau-web-connect-page .inner.first img {
    width: 220px;
    object-fit: contain;
}

.tableau-web-connect-page .ant-select {
    width: 100%;
}

.tableau-web-connect-page .inner.first .menu-icon {
    display: none;
}

.tableau-web-connect-page .semibold {
    margin-bottom: 0;
}
.tableau-web-connect-page .semibold,
.tableau-web-connect-page .semibold * {
    font-weight: 700 !important;
}
.tableau-web-connect-page .inner .para a {
    margin-left: 5px;
}

.tableau-web-connect-page .next-butt.ant-btn-primary {
    font-weight: bold;
    margin-top: 20px;
}

.tableau-web-connect-page .ant-input {
    box-shadow: none;
}
